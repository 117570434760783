import { Component, Inject } from '@angular/core';
import{MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog"
import { FormGroup,FormControl, FormBuilder,Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';


@Component({
  selector: 'app-highalloyandnonferroussteel',
  templateUrl: './highalloyandnonferroussteel.component.html',
  styleUrls: ['./highalloyandnonferroussteel.component.css']
})
export class HighalloyandnonferroussteelComponent {
highalloyform : FormGroup;

x1: any;
x2: any;
Result:string ="";
input1:number;
input2:number;
path: string;
isDisabled: boolean = true;
typaResult:boolean = null;
intUnit:number;
// inputval11:number;
// inputval22:number;
// resultmessage:string;
  constructor(private router: Router, private formBuilder:FormBuilder, public dialogRef:MatDialogRef<HighalloyandnonferroussteelComponent>, @Inject(MAT_DIALOG_DATA) public data: any ){
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
     }
  ngOnInit()
  {
    this.highalloyform = this.formBuilder.group (
      {
        input1: new FormControl('',Validators.required),
        input2: new FormControl('',Validators.required),
       }
    )
  }
  closeDialog(){
    // for Cancel button click
      
    //this.dialogRef.close({ data1:  1, data2:this.typaResult });
    this.dialogRef.close({ cancel: true, value: [] });
  }
  BacktoALS(){
    
    // for the Next button click
    if ((this.highalloyform.get('input1').invalid) || (this.highalloyform.get('input2').invalid))
      {
       this.Result = "Please enter the correct values.";
       this.isDisabled = true;
       this.typaResult = null;
      }
      else{
       this.dialogRef.close({ cancel: false, value: [2 , this.typaResult] });
      }

  }
  onSubmit() {
   
     // for Calculate button
      console.log(this.highalloyform.value);
      console.log( this.highalloyform.controls.input1.value);

      this.x1 = this.highalloyform.controls.input1.value;
      this.x2 = this.highalloyform.controls.input2.value;
  

    if ((this.highalloyform.get('input1').invalid) || (this.highalloyform.get('input2').invalid))
      {
        this.Result = "Please enter the correct values.";
        this.isDisabled = true;
        this.typaResult = null;
      }
      else
      {
        if(this.intUnit == 0) ///us customary
        {
            if((((this.x2 <= 10)&&(this.x2 >4))&&((this.x1 <= 200)&&(this.x1 >= -50))) ||
            (((this.x2 <= 4)&&(this.x2 >0))&&((this.x1 <= 200)&&(this.x1 >= -150))) )
      
            {
              this.Result = "The component meets Type A component screening criteria."
              this.isDisabled = false;
              this.typaResult = true;
            }
            else
            {
              this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
              this.isDisabled = false;
              this.typaResult = false;
            }
       }
       else if(this.intUnit == 1)//si units
       {
        if((((this.x2 <= 250)&&(this.x2 >100))&&((this.x1 <= 93)&&(this.x1 >= -46))) ||
        (((this.x2 <= 100)&&(this.x2 >0))&&((this.x1 <= 93)&&(this.x1 >= -101))) )
  
        {
          this.Result = "The component meets Type A component screening criteria."
          this.isDisabled = false;
          this.typaResult = true;
        }
        else
        {
          this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
          this.isDisabled = false;
          this.typaResult = false;
        }

       }

      }
    
  }
  
}
