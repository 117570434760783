import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog"
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-horizontalvessel',
  templateUrl: './horizontalvessel.component.html',
  styleUrls: ['./horizontalvessel.component.css']
})
export class HorizontalvesselComponent {
  horizontalform : FormGroup;
   
  input1:number;
  input2:number;
  input3:number;
  x1: any;
  x2: any;
  x3: any;
  Result:string ="";
  isDisabled: boolean = true;
  path: string;
  typaResult:boolean = null;
  intUnit:number;
 
  constructor(private router: Router,private formBuilder:FormBuilder,public dialogRef:MatDialogRef<HorizontalvesselComponent>, @Inject(MAT_DIALOG_DATA) public data: any ){
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
     }
  ngOnInit()
  {
    this.horizontalform = this.formBuilder.group (
      {
        input1: new FormControl('',Validators.required),
        input2: new FormControl('',Validators.required),
        input3: new FormControl('',Validators.required),
       }
    )
  }
  closeDialog(){
   // for Cancel button click
    this.dialogRef.close({ cancel: true, value: [] });
   }
  BacktoALS(){
    // for the Next button click

   // this.dialogRef.close(this.typaResult);
    // to tell as it is returning from Next button
   // this.data2 = this.typaResult;
   if ((this.horizontalform.get('input1').invalid) || (this.horizontalform.get('input2').invalid) || (this.horizontalform.get('input3').invalid))
   {
    this.Result = "Please enter the correct values.";
    this.isDisabled = true;
    this.typaResult = null;
   }
   else{
    this.dialogRef.close({ cancel: false, value: [2 , this.typaResult] });
   }
  }
  onSubmit() {
   // for Calculate button
    console.log(this.horizontalform.value);
     console.log( this.horizontalform.controls.input1.value);
   
          this.x1 = this.horizontalform.controls.input1.value;
          this.x2 = this.horizontalform.controls.input2.value;
          this.x3 = this.horizontalform.controls.input3.value;

          if ((this.horizontalform.get('input1').invalid) || (this.horizontalform.get('input2').invalid) || (this.horizontalform.get('input3').invalid))
          {
            this.Result = "Please enter the correct values.";
            this.isDisabled = true;
            this.typaResult = null;
          }
          else
          {
            if(this.intUnit == 0)  //us customary
            {
            if(((this.x3/this.x2) <= 2.5) && (this.x2 <= 120))
              {
                this.Result = "The component meets Type A component screening criteria."
                this.isDisabled = false;
                this.typaResult = true;
              }
              else
              {
                this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
                this.isDisabled = false;
                this.typaResult = false;
              }
            } else if(this.intUnit == 1) //si units
            {
              if(((this.x3/this.x2) <= 2.5) && (this.x2 <= 3050))
                {
                  this.Result = "The component meets Type A component screening criteria."
                  this.isDisabled = false;
                  this.typaResult = true;
                }
                else
                {
                  this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
                  this.isDisabled = false;
                  this.typaResult = false;
                }

            }

          }

  }
 
}
