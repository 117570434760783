import { Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HeaderComponent, MenuComponent } from "@cvx/nextpage";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { HomeComponent } from "./home/home.component";
import { GeneralMetalLossComponent } from "./assessments/general-metal-loss/general-metal-loss.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalMetalLossComponent } from "./assessments/local-metal-loss/local-metal-loss.component";
import { LocalMetalLossProfileDataComponent } from "./assessments/local-metal-loss-profile-data/local-metal-loss-profile-data.component";
import { GougesComponent } from "./assessments/gouges/gouges.component";
import { PittingCorrosionComponent } from "./assessments/pitting-corrosion/pitting-corrosion.component";
import { BlistersAndHICDamageComponent } from "./assessments/blisters-and-hic-damage/blisters-and-hic-damage.component";
import { CrackLikeIndicationsComponent } from "./assessments/crack-like-indications/crack-like-indications.component";
import { DentsComponent } from "./assessments/dents/dents.component";
import { DentGougeCombinationsComponent } from "./assessments/dents-gouge-combinations/dents-gouge-combinations.component";
import { LaminationsComponent } from "./assessments/laminations/laminations.component";
import { InspectionDataComponent } from "./inspection-data/inspection-data.component";
import { SharedModule } from "./shared.module";
import { PittingInformationComponent } from "./assessments/pitting-corrosion/pitting-information/pitting-information.component";
import { MAWPComponent } from "./mawp/mawp.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DebugCmpComponent } from "./debug-cmp/debug-cmp.component";
import { ComponentInformationComponent } from "./inspection-data/component-information/component-information.component";
import { UnitsComponent } from "./units/units.component";
import { CrackChartComponent } from "./assessments/crack-like-indications/crack-chart/crack-chart.component";
import { ValidationComponent } from "./validation/validation.component";
import { DentChartComponent } from "./assessments/dents-gouge-combinations/dent-chart/dent-chart.component";
import { LabelDisableDirective } from "../directives/label-disable.directive";
import { RestrictInputDirective } from "../directives/restrict-input.directive";
import { WarningModalComponent } from "./warning-modal/warning-modal.component";
import { LeftMenuComponent } from "./left-menu/left-menu.component";
import { ImportExportComponent } from "./import-export/import-export.component";
import { HttpClientModule } from "@angular/common/http";
import { HelpComponent } from "./help/help.component";
import { AboutComponent } from "./about/about.component";
import { PrintReportComponent } from "./print-report/print-report.component";
import { PrintReportModalComponent } from "./print-report/print-report-modal/print-report-modal.component";
import { ReportTemplateComponent } from "./print-report/report-template/report-template.component";
import { PopoutService } from "../services/popout.service";
import { AdditionalInformationComponent } from "./additional-component-information/additional-component-information.component";
import { ComponentEventService } from "../services/component_event.service";
import { CalAngularModule } from "@cvx/cal-angular";
import { ContactComponent } from "./contact/contact.component";
import { RouterModule } from "@angular/router";
import { MainTopMenuComponent } from "./main-top-menu/main-top-menu.component";

const componentList = [
  AppComponent,
  MenuComponent,
  HeaderComponent,
  LocalMetalLossComponent,
  LocalMetalLossProfileDataComponent,
  GougesComponent,
  PittingCorrosionComponent,
  BlistersAndHICDamageComponent,
  CrackLikeIndicationsComponent,
  DentsComponent,
  DentGougeCombinationsComponent,
  HelpComponent,
  AboutComponent,
  ContactComponent,
  LaminationsComponent,
  MAWPComponent,
  ComponentInformationComponent,
  CrackChartComponent,
  DentChartComponent,
  HomeComponent,
  GeneralMetalLossComponent,
  PittingInformationComponent,
  InspectionDataComponent,
  DebugCmpComponent,
  ValidationComponent,
  UnitsComponent,
  LabelDisableDirective,
  RestrictInputDirective,
  WarningModalComponent,
  LeftMenuComponent,
  ImportExportComponent,
  PrintReportComponent,
  PrintReportModalComponent,
  ReportTemplateComponent,
  AdditionalInformationComponent,
  MainTopMenuComponent
];

@NgModule({
  declarations: [componentList],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTableModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    SharedModule,
    RouterModule,
    CalAngularModule.forRoot('assets/config.json'),
  ],
  exports: [],
  bootstrap: [AppComponent],
  providers: [PopoutService, ComponentEventService],
})
export class AppTest {
  constructor(private injector: Injector) { }
}
