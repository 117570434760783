import { Component, Inject } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormBuilder, FormControl, FormGroup, FormsModule,ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { HighalloyandnonferroussteelComponent } from '../highalloyandnonferroussteel/highalloyandnonferroussteel.component';
import { MatDialogRef,MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-carbonsteelandlowalloysteel',
  templateUrl: './carbonsteelandlowalloysteel.component.html',
  styleUrls: ['./carbonsteelandlowalloysteel.component.css']
})
export class CarbonsteelandlowalloysteelComponent {
  

public carbonsteelform: FormGroup;
inputval1:number;
inputval2:number;
resultmessage:string;

x1: any;
x2: any;
Result:string ="";
isDisabled: boolean = true;
path: string;
typaResult:boolean = null;
intUnit:number;

  constructor(private router: Router,private formBuilder:FormBuilder,public dialogRef:MatDialogRef<CarbonsteelandlowalloysteelComponent>,@Inject(MAT_DIALOG_DATA) public data: any){
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
  }
  ngOnInit()
  {
    this.carbonsteelform = this.formBuilder.group (
      {
        input1: new FormControl('',Validators.required),
        input2: new FormControl('',Validators.required),
     }
    )
  }

  closeDialog(){
     // for Cancel button click
       this.dialogRef.close({ cancel: true, value: [] });
    }
  BacktoALS(){
     // for the Next button click

   // this.dialogRef.close(this.typaResult);
    // to tell as it is returning from Next button
   // this.data2 = this.typaResult;
   if ((this.carbonsteelform.get('input1').invalid) || (this.carbonsteelform.get('input2').invalid))
    {
     this.Result = "Please enter the correct values.";
     this.isDisabled = true;
     this.typaResult = null;
    }
    else{
     this.dialogRef.close({ cancel: false, value: [2 , this.typaResult] });
    }
  }
  
  onSubmit()
  {
    // for Calculate button
    this.x1 = this.carbonsteelform.controls.input1.value;
    this.x2 = this.carbonsteelform.controls.input2.value;
    
    if ((this.carbonsteelform.get('input1').invalid) || (this.carbonsteelform.get('input2').invalid))
      {
        this.Result = "Please enter the correct values.";
        this.isDisabled = true;
        this.typaResult = null;
      }
      else
      {
        if(this.intUnit == 0)  //us customary
        {
        if((((this.x2 <= 16)&&(this.x2 >=8))&&((this.x1 <= 200)&&(this.x1 >= -50))) ||
        (((this.x2 <= 8)&&(this.x2 >=4))&&((this.x1 <= 300)&&(this.x1 >= -50))) ||
        (((this.x2 <= 4)&&(this.x2 >=0))&&((this.x1 <= 400)&&(this.x1 >= -50))))
        {
          this.Result = "The component meets Type A component screening criteria."
          this.isDisabled = false;
          this.typaResult = true;
        }
        else
        {
          this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
          this.isDisabled = false;
          this.typaResult = false;
        }
      } else if(this.intUnit == 1) //si units
      {
        if((((this.x2 <= 400)&&(this.x2 >200))&&((this.x1 <= 204)&&(this.x1 >= -46))) ||
        (((this.x2 <= 200)&&(this.x2 >100))&&((this.x1 <= 149)&&(this.x1 >= -46))) ||
        (((this.x2 <= 100)&&(this.x2 >0))&&((this.x1 <= 204)&&(this.x1 >= -46))))
        {
          this.Result = "The component meets Type A component screening criteria."
          this.isDisabled = false;
          this.typaResult = true;
        }
        else
        {
          this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
          this.isDisabled = false;
          this.typaResult = false;
        }
      }

      }


    // if((((this.x2 <= 16)&&(this.x2 >=8))&&((this.x1 <= 200)&&(this.x1 >= -50))) ||
    //   (((this.x2 <= 8)&&(this.x2 >=4))&&((this.x1 <= 300)&&(this.x1 >= -50))) ||
    //   (((this.x2 <= 4)&&(this.x2 >=0))&&((this.x1 <= 400)&&(this.x1 >= -50))))
    // {
    //   this.Result = "The component meets Type A component screening criteria."
    // }
    // else
    // {
    //   this.Result = "The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
    // }
  }
}
