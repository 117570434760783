// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your component's CSS file */
.hide-scrollbar {
    overflow-y: hidden;
    -ms-overflow-style: hidden; /* IE and Edge */
    scrollbar-width: hidden; /* Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
    
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }`, "",{"version":3,"sources":["webpack://./projects/fitnessforservice/src/app/type-a-component/verticalvessel/verticalvessel.component.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,kBAAkB;IAClB,0BAA0B,EAAE,gBAAgB;IAC5C,uBAAuB,EAAE,YAAY;IACrC,wBAAwB,EAAE,mCAAmC;EAC/D,qBAAqB,EAAE,+BAA+B;;EAEtD;;EAEA;IACE,aAAa,EAAE,6BAA6B;EAC9C","sourcesContent":["/* In your component's CSS file */\n.hide-scrollbar {\n    overflow-y: hidden;\n    -ms-overflow-style: hidden; /* IE and Edge */\n    scrollbar-width: hidden; /* Firefox */\n    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */\n  scrollbar-width: none; /* Hide scrollbar for Firefox */\n    \n  }\n  \n  .hide-scrollbar::-webkit-scrollbar {\n    display: none; /* Chrome, Safari and Opera */\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
