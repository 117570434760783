import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Component_MAWP } from "../../../services/component_mawp";
import { Subject } from "rxjs";
import { Form1 } from "../../../services/form1";
import { Level1Data } from "../../../services/level1Data";
import { Laminations_module } from "../../../services/laminations_module";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { VerticalvesselComponent } from "../../type-a-component/verticalvessel/verticalvessel.component";
import { HorizontalvesselComponent } from "../../type-a-component/horizontalvessel/horizontalvessel.component";
import { CarbonsteelandlowalloysteelComponent } from "../../type-a-component/carbonsteelandlowalloysteel/carbonsteelandlowalloysteel.component";
import { HighalloyandnonferroussteelComponent } from "../../type-a-component/highalloyandnonferroussteel/highalloyandnonferroussteel.component";
import { Overlay } from "@angular/cdk/overlay";


@Component({
  selector: "laminations",
  templateUrl: "laminations.component.html",
})
export class LaminationsComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  public level1Data = Level1Data;

  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  summary: string;
  inspectionDataResult: string;
  result: string;
  SelectedValue: string;
  isCheckboxDisabled = false;
  isCylAtmradio = true; // always disabled this radio button for the current requirement

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
    typeACheckbox:["",Validators.required],
    radio:["",],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;

    this.Laminations_Assesment_Setup();
     //shameer   
     this.firstFormGroup.get('typeACheckbox').disable();
     this.firstFormGroup.get('applicabilityCheckbox').disable();
  }

  goToComponent() {

    if(((this.firstFormGroup.get('radio').value) != null) &&
    ((this.firstFormGroup.get('applicabilityCheckbox').enabled == true ) ||
    (this.firstFormGroup.get('typeACheckbox').enabled == true))
  )
{
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });
 
     //shameer
     let typAcheckbox1 = this.firstFormGroup.controls["typeACheckbox"];
     typAcheckbox1.markAsDirty({
       onlySelf: true,
     });
     typAcheckbox1.markAsTouched({
       onlySelf: true,
     });

    this.goNextStep();
  }else{
    this.dialog.open(WarningModalComponent, {
      width: "450px",
      data: {
        warningMessage: [
          "Select an Option to Proceed!..Please.",
        ],
        caption: "FFS: Applicability and Limitations",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }
}

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  ngOnInit(): void {
    this.summary = "";
    this.result = "";
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria), or is in cyclic service and adjacent laminations are in the same plane and there is no indication of through-thickness cracking.</li>  
        <li>Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness.  Examples are:
          <ol type="a">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
        <li>Laminations should be evaluated even when the lamination is located within the region of the specified corrosion/erosion allowance.</li>  
      </ol>  
    `;
    localStorage.setItem('calculationStep', '0'); //this is zero step (Applicability)

    this.formControlsEventProxyService.getLaminationsApplicabilityEventSubject().subscribe((v) => {
      this.updateApplicabilityCheckboxToChecked();
    });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

    //shameer
    updateApplicabilityCheckboxToUnChecked() {
      let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
      applicabilityCheckbox.setValue(false);
    }
  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem('calculationStep', (this.myStepper.selectedIndex + 1).toString());

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem('calculationStep', (this.myStepper.selectedIndex - 1).toString());
    this.myStepper.previous();
  }

  Laminations_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();
    Laminations_module.Laminations_initialize();

    Component_MAWP.strD_class = "Lamination";
    Component_MAWP.strD_iclass = 9;
    Component_MAWP.intUnit_select =
      parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Lamination";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }

  onRadioChange(event:MatRadioChange)
  {
    console.log('Selected value',event.value);
    if(event.value ==1)
    {
      const dialogRef = this.dialog.open(VerticalvesselComponent,{height : '800px' ,width :'2000px',disableClose :true })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Data from dialog:', result.data1,result.data2); 
        if (result.cancel) {

          this.updateTypeAradioToUncheck();
          this.updateApplicabilityCheckboxToUnChecked();
          this.updatetypeACheckboxToUnChecked();
          this.firstFormGroup.get('applicabilityCheckbox').disable();
          this.firstFormGroup.get('typeACheckbox').disable();

        } else {
          console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
          if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
            {
                this.updateApplicabilityCheckboxToUnChecked();
                this.firstFormGroup.get('applicabilityCheckbox').disable();
                this.updatetypeACheckboxToUnChecked();
                this.firstFormGroup.get('typeACheckbox').enable();
            
            }
            else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
              {
                this.updateApplicabilityCheckboxToUnChecked();
                this.firstFormGroup.get('applicabilityCheckbox').enable();
                this.updatetypeACheckboxToUnChecked();
                this.firstFormGroup.get('typeACheckbox').disable();
              }
        }
     
      }
    });   
    }
    else if(event.value==2)
    {
      const dialogRef = this.dialog.open(HorizontalvesselComponent,{height : '800px' ,width :'2000px',disableClose :true})
     
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });
    }
    else if(event.value==3)
    {
      const dialogRef = this.dialog.open(CarbonsteelandlowalloysteelComponent,{height : '800px' ,width :'2000px',disableClose :true})
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });
    }
    else if(event.value==4)
    {
       const dialogRef = this.dialog.open(HighalloyandnonferroussteelComponent,{height : '800px' ,width :'2000px'})
       dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });

    }
    else
    {   // when we select 5 or 6 or 7 radio buttons
       this.updateApplicabilityCheckboxToUnChecked();
       this.firstFormGroup.get('applicabilityCheckbox').enable();
       this.updatetypeACheckboxToUnChecked();
       this.firstFormGroup.get('typeACheckbox').disable();
    }
   
  }
  updateTypeAradioToUncheck()
  {
    this.firstFormGroup.get('radio').patchValue(null);    
  }

  gettypeAradio_formControl(): FormControl {
    return this.firstFormGroup.get("radio") as FormControl;
  }

  updatetypeACheckboxToChecked(){
    let typeAcheckbox = this.gettypeACheckbox_formControl();
    typeAcheckbox.setValue(true);
  }

  updatetypeACheckboxToUnChecked(){
    let typeAcheckbox = this.gettypeACheckbox_formControl();
    typeAcheckbox.setValue(false);
  }
  gettypeACheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("typeACheckbox") as FormControl;
  }
}
