import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NextPageService, NavigationLocation, PageLayout } from "@cvx/nextpage";
import { Component_MAWP } from "../../../services/component_mawp";
import { Subject } from "rxjs";
import { LTA_module } from "../../../services/lta_module";
import { LTAP_module } from "../../../services/ltap_module";
import { Form1 } from "../../../services/form1";
import { Level1Data } from "../../../services/level1Data";
import { Location } from "../../../services/locationInterface";
import { MatStepper } from "@angular/material/stepper";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";
import { MatRadioChange } from "@angular/material/radio";
import { VerticalvesselComponent } from "../../type-a-component/verticalvessel/verticalvessel.component";
import { HorizontalvesselComponent } from "../../type-a-component/horizontalvessel/horizontalvessel.component";
import { CarbonsteelandlowalloysteelComponent } from "../../type-a-component/carbonsteelandlowalloysteel/carbonsteelandlowalloysteel.component";
import { HighalloyandnonferroussteelComponent } from "../../type-a-component/highalloyandnonferroussteel/highalloyandnonferroussteel.component";
import { Overlay } from "@angular/cdk/overlay";

@Component({
  selector: "local-metal-loss-profile-data",
  templateUrl: "local-metal-loss-profile-data.component.html",
  styleUrls: ["local-metal-loss-profile-data.component.css"],
})
export class LocalMetalLossProfileDataComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;

  inspectionDataCalculate: Subject<boolean> = new Subject();
  disable_intnum_t_pt: Subject<boolean> = new Subject();
  public level1Data = Level1Data;

  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  summary: string;
  result: string;
  inspectionDataResult: string;
  SelectedValue: string;
  isCheckboxDisabled = false;
  isCylAtmradio = true; // always disabled this radio button for the current requirement

  displayedColumns: string[] = ["selected", "x", "y"];
  dataSource: Location[];

  componentType: string;

  lmlProfileData_intnum_t_pt: number;
  lmlProfileData_sngGrid_spacing: number;

  editTableText: string;

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
    typeACheckbox:["",Validators.required],
    radio:["",],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;
  isEditing = false;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private formControlsEventProxyService: FormControlsEventProxyService    
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;

    this.LTAP_Assesment_Setup();
     //shameer   
     this.firstFormGroup.get('typeACheckbox').disable();
     this.firstFormGroup.get('applicabilityCheckbox').disable();
  }

  goToComponent() {

    if(((this.firstFormGroup.get('radio').value) != null) &&
    ((this.firstFormGroup.get('applicabilityCheckbox').enabled == true ) ||
    (this.firstFormGroup.get('typeACheckbox').enabled == true))
  )
{
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });

    //shameer
    let typAcheckbox1 = this.firstFormGroup.controls["typeACheckbox"];
    typAcheckbox1.markAsDirty({
      onlySelf: true,
    });
    typAcheckbox1.markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  } else{
    this.dialog.open(WarningModalComponent, {
      width: "450px",
      data: {
        warningMessage: [
          "Select an Option to Proceed!..Please.",
        ],
        caption: "FFS: Applicability and Limitations",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }
}

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  ngOnInit(): void {
    this.summary = "";
    this.result = "";
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness.  Examples are: 
          <ol type="a">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
      </ol>  
    `;
    localStorage.setItem("calculationStep", "0"); //this is zero step (Applicability)

    this.formControlsEventProxyService
      .getLTAP_ApplicabilityEventSubject()
      .subscribe((v) => {
        this.updateApplicabilityCheckboxToChecked();
      });

    this.editTableText = "Edit table";
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }
//shameer
updateApplicabilityCheckboxToUnChecked() {
  let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
  applicabilityCheckbox.setValue(false);
}
  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem(
        "calculationStep",
        (this.myStepper.selectedIndex + 1).toString()
      );

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem(
      "calculationStep",
      (this.myStepper.selectedIndex - 1).toString()
    );
    this.myStepper.previous();
  }

  LTAP_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    LTAP_module.LTAP_initialize();
    this.lmlProfileData_intnum_t_pt = LTAP_module.intnum_t_pt;
    this.lmlProfileData_sngGrid_spacing = LTAP_module.sngGrid_spacing;

    Component_MAWP.strD_class = "LTAP";
    Component_MAWP.strD_iclass = 2;
    Component_MAWP.intUnit_select =
      parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "LTAP";
  }

  onYChange(element: Location, newValue) {
    element.y = Number(newValue);
  }

  prepareThicknessProfileTable() {
    if (LTAP_module.sngGrid_spacing != 0) {
      // 0 means dataSource shouldnt be changed, because was filled with custom imported data
      let tempMap: Map<number, number> = new Map<number, number>();

      for (let i = 1; i <= LTAP_module.intnum_t_pt; i++) {
        let xx = LTAP_module.sngGrid_spacing * (i - 1);
        if (
          LTAP_module.grid_yes >= 0 &&
          LTAP_module.dataSource[i - 1] !== undefined
        )
          tempMap.set(xx, LTAP_module.dataSource[i - 1].y);
        else tempMap.set(xx, 0);
      }

      const myArray = Array.from(tempMap, ([x, y]) => ({
        selected: false,
        x,
        y,
      }));
      this.dataSource = [...myArray];
      LTAP_module.dataSource = [...myArray];
    } else {
      this.dataSource = LTAP_module.dataSource;
    }

    this.inspectionDataCalculate.next(true);
  }

  copySelectedRows() {
    let nodeData: Location[] = this.dataSource.filter(
      (item) => item.selected === true
    );
    let tbl: HTMLTableElement = document.createElement("table");
    let tblBody: HTMLTableSectionElement = document.createElement("tbody");
    nodeData.forEach((data) => {
      let row: HTMLTableRowElement = document.createElement("tr");
      row.innerHTML = `<td>${data.x}</td><td>${data.y}</td>`;
      tblBody.appendChild(row);
    });
    tbl.appendChild(tblBody);
    document.body.appendChild(tbl);
    navigator.clipboard.writeText(tbl.innerText);
    tbl.style.display = "none";
  }

  chooseFile(): void {
    document.getElementById("csvFileInput")?.click();
    const fileInput = document.getElementById(
      "csvFileInput"
    ) as HTMLInputElement;
    fileInput.value = "";
  }

  editTable(element: any) {
    if (!this.isEditing) {
      this.isEditing = true;
      this.editTableText = "Save table";
    } else {
      this.isEditing = false;
      this.editTableText = "Edit table";
    }
  }

  selectAll(event: any) {
    const checked = event.checked;
    this.dataSource.forEach((element) => {
      element.selected = checked;
    });
  }

  selectRow(event: any, element: any) {
    element.selected = event.checked;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.parseCSVFile(file);
  }

  parseCSVFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result as string;
      this.importDataFromCSV(csvData);
    };
    reader.readAsText(file);
  }

  importDataFromCSV(csvData: string): void {
    LTAP_module.intnum_t_pt = 0;
    LTAP_module.sngGrid_spacing = 0;

    const tempMap: Location[] = [];

    const selected = false;
    const lines = csvData.split("\n");
    for (const line of lines) {
      if (line.trim() === "") {
        continue; // Skip empty lines, avoid filling the map with 'NaN' values
      }
      const [xStr, yStr] = line.split(",");
      const x = parseFloat(xStr);
      const y = parseFloat(yStr);
      tempMap.push({ selected, x, y });
      ++LTAP_module.intnum_t_pt;
    }

    this.lmlProfileData_intnum_t_pt = LTAP_module.intnum_t_pt;
    this.lmlProfileData_sngGrid_spacing = LTAP_module.sngGrid_spacing;
    this.disable_intnum_t_pt.next(true);

    this.dataSource = tempMap;
    LTAP_module.dataSource = tempMap;
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  validateThicknessProfileDataTable() {
    LTAP_module.grid_yes = 0;
    LTAP_module.grid_ok = 0;
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i].x < 0 || this.dataSource[i].y <= 0) {
        LTAP_module.grid_ok = -1;
      }
    }
  }

  goToPreviousStepFromThicknessData() {
    this.validateThicknessProfileDataTable();
    if (LTAP_module.grid_ok === -1) {
      this.openWrongInputErrorModal();
      return;
    }

    this.myStepper.previous();
  }

  calculateResult(): void {
    this.validateThicknessProfileDataTable();
    if (LTAP_module.grid_ok === -1) {
      this.openWrongInputErrorModal();
      return;
    }

    LTAP_module.LTA2_RSF(Component_MAWP.intEquip, this.dataSource);
    this.result = LTA_module.LTA_calc();
    this.myStepper.next();
  }

  private openWrongInputErrorModal(): void {
    this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: [
          "Check your input! Either missing data, zeros, or negative values !",
        ],
        caption: "Error",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }
  onRadioChange(event:MatRadioChange)
  {
    console.log('Selected value',event.value);
    if(event.value ==1)
    {
      const dialogRef = this.dialog.open(VerticalvesselComponent,{height : '800px' ,width :'2000px',disableClose :true })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Data from dialog:', result.data1,result.data2); 
        if (result.cancel) {

          this.updateTypeAradioToUncheck();
          this.updateApplicabilityCheckboxToUnChecked();
          this.updatetypeACheckboxToUnChecked();
          this.firstFormGroup.get('applicabilityCheckbox').disable();
          this.firstFormGroup.get('typeACheckbox').disable();

        } else {
          console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
          if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
            {
                this.updateApplicabilityCheckboxToUnChecked();
                this.firstFormGroup.get('applicabilityCheckbox').disable();
                this.updatetypeACheckboxToUnChecked();
                this.firstFormGroup.get('typeACheckbox').enable();
            
            }
            else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
              {
                this.updateApplicabilityCheckboxToUnChecked();
                this.firstFormGroup.get('applicabilityCheckbox').enable();
                this.updatetypeACheckboxToUnChecked();
                this.firstFormGroup.get('typeACheckbox').disable();
              }
        }
     
      }
    });   
    }
    else if(event.value==2)
    {
      const dialogRef = this.dialog.open(HorizontalvesselComponent,{height : '800px' ,width :'2000px',disableClose :true})
     
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });
    }
    else if(event.value==3)
    {
      const dialogRef = this.dialog.open(CarbonsteelandlowalloysteelComponent,{height : '800px' ,width :'2000px',disableClose :true})
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });
    }
    else if(event.value==4)
    {
       const dialogRef = this.dialog.open(HighalloyandnonferroussteelComponent,{height : '800px' ,width :'2000px'})
       dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Data from dialog:', result.data1,result.data2); 
          if (result.cancel) {

            this.updateTypeAradioToUncheck();
            this.updateApplicabilityCheckboxToUnChecked();
            this.updatetypeACheckboxToUnChecked();
            this.firstFormGroup.get('applicabilityCheckbox').disable();
            this.firstFormGroup.get('typeACheckbox').disable();

          } else {
            console.log(result.value); // 2- for Next button  and TypeAresult as it is passed or failed true/false
            if(result.value[0] == 2 && result.value[1] == false) //typea fail scenario
              {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').disable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').enable();
              
              }
              else if(result.value[0] == 2 && result.value[1] == true) //typea pass scenario
                {
                  this.updateApplicabilityCheckboxToUnChecked();
                  this.firstFormGroup.get('applicabilityCheckbox').enable();
                  this.updatetypeACheckboxToUnChecked();
                  this.firstFormGroup.get('typeACheckbox').disable();
                }
          }
       
        }
      });

    }
    else
    {   // when we select 5 or 6 or 7 radio buttons
       this.updateApplicabilityCheckboxToUnChecked();
       this.firstFormGroup.get('applicabilityCheckbox').enable();
       this.updatetypeACheckboxToUnChecked();
       this.firstFormGroup.get('typeACheckbox').disable();
    }
   
  }
  updateTypeAradioToUncheck()
  {
    this.firstFormGroup.get('radio').patchValue(null);    
  }

  gettypeAradio_formControl(): FormControl {
    return this.firstFormGroup.get("radio") as FormControl;
  }

  updatetypeACheckboxToChecked(){
    let typeAcheckbox = this.gettypeACheckbox_formControl();
    typeAcheckbox.setValue(true);
  }

  updatetypeACheckboxToUnChecked(){
    let typeAcheckbox = this.gettypeACheckbox_formControl();
    typeAcheckbox.setValue(false);
  }
  gettypeACheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("typeACheckbox") as FormControl;
  }

}
