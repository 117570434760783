import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
} from "@cvx/nextpage";
import { VerticalvesselComponent } from "./type-a-component/verticalvessel/verticalvessel.component";
import { HorizontalvesselComponent } from "./type-a-component/horizontalvessel/horizontalvessel.component";
import { CarbonsteelandlowalloysteelComponent } from "./type-a-component/carbonsteelandlowalloysteel/carbonsteelandlowalloysteel.component";
import { HighalloyandnonferroussteelComponent } from "./type-a-component/highalloyandnonferroussteel/highalloyandnonferroussteel.component";
import { ReactiveFormsModule } from "@angular/forms";


const sharedComponents = [
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
  VerticalvesselComponent,
  HorizontalvesselComponent,
  CarbonsteelandlowalloysteelComponent,
  HighalloyandnonferroussteelComponent

];

@NgModule({
  declarations: [sharedComponents],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [sharedComponents],
})
export class SharedModule {}
