import { Component, Inject } from '@angular/core';
import{MatDialogRef,MAT_DIALOG_DATA } from "@angular/material/dialog"
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-verticalvessel',
  templateUrl: './verticalvessel.component.html',
  styleUrls: ['./verticalvessel.component.css']
})
export class VerticalvesselComponent {
  verticalform : FormGroup;
  input1: number;
  input2: number;
  // input1 = new FormControl('',Validators.required);
  // input2 = new FormControl('',Validators.required);
  resultmessage : string;
  x1: any;
  x2: any;
  Result:string ="";
  Result1:boolean;
  isDisabled: boolean = true;
  path: string;
  typaResult:boolean = null;
  intUnit:number;
  
  
  constructor(private router: Router, private formBuilder:FormBuilder, public dialogRef:MatDialogRef<VerticalvesselComponent>,@Inject(MAT_DIALOG_DATA) public data: any){
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
  }
  ngOnInit(){
    this.verticalform = this.formBuilder.group (
      {
        input1: new FormControl('',Validators.required),
        input2: new FormControl('',Validators.required),
      }
    )
}
  closeDialog(){
    // for Cancel button click
      this.dialogRef.close({ cancel: true, value: [] });
     }
  BacktoALS(){
    // for the Next button click

   // this.dialogRef.close(this.typaResult);
    // to tell as it is returning from Next button
   // this.data2 = this.typaResult;
   if ((this.verticalform.get('input1').invalid) || (this.verticalform.get('input2').invalid))
    {
     this.Result = "Please enter the correct values.";
     this.isDisabled = true;
     this.typaResult = null;
    }
    else{
     this.dialogRef.close({ cancel: false, value: [2 , this.typaResult] });
    }
  }

  onSubmit()
  {
    //for Calculate button
          this.x1 = this.verticalform.controls.input1.value;
          this.x2 = this.verticalform.controls.input2.value;

          if ((this.verticalform.get('input1').invalid) || (this.verticalform.get('input2').invalid))
            {
              this.Result = "Please enter the correct values.";
              this.isDisabled = true;
              this.typaResult = null;
            }
            else
            {
              if(this.intUnit == 0)  //us customary
              {
              if (((12*this.x1/this.x2) <= 3) && (this.x1 <= 100))
                {
                  this.Result ="The component meets Type A component screening criteria."
                  this.isDisabled = false;
                  this.typaResult = true;
                }
                else
                {
                  this.Result ="The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
                  this.isDisabled = false;
                  this.typaResult = false;
                }
              }
              else if(this.intUnit == 1) //si units
              {
                if (((12*this.x1/this.x2) <= 3) && (this.x1 <= 30500))
                  {
                    this.Result ="The component meets Type A component screening criteria."
                    this.isDisabled = false;
                    this.typaResult = true;
                  }
                  else
                  {
                    this.Result ="The component doesn't meet the Type A component criteria. A Level 2 or Level 3 assessment is recommended."
                    this.isDisabled = false;
                    this.typaResult = false;
                  }
              }

            }

          
         
  }
   
}
